<template>
  <v-tabs v-model="tabsNavigation" show-arrows>
    <v-tab v-for="(item, index) in allAssessments || []" :key="`tab-${item.sIndex}-${item.aIndex}`">
      Assessment {{ index + 1 }}
    </v-tab>

    <v-tabs-items v-model="tabsNavigation" class="pa-5">
      <v-tab-item v-for="(item, index) in allAssessments || []" :key="`details-${item.sIndex}-${item.aIndex}`">
        <h3>Session Type: {{ item.session.type || 'No Data' }}</h3>
        <h4>Session Category: {{ item.session.category || 'No Data' }}</h4>

        <dl>
          <dt>Id</dt>
          <dd>{{ item.assessment.id || 'No Data' }}</dd>

          <dt>Title of session</dt>
          <dd :class="{ empty: !item.assessment.titleOfSession }">
            {{ item.assessment.titleOfSession || 'No Data' }}
          </dd>

          <dt>Unique key</dt>
          <dd :class="{ empty: !item.assessment.uniqueKey }">
            {{ item.assessment.uniqueKey || 'No Data' }}
          </dd>

          <dt>Session type</dt>
          <dd :class="{ empty: !item.assessment.sessionType }">
            {{ item.assessment.sessionType || 'No Data' }}
          </dd>

          <dt>Category</dt>
          <dd :class="{ empty: !item.assessment.category }">
            {{ item.assessment.category || 'No Data' }}
          </dd>

          <dt>Level</dt>
          <dd :class="{ empty: !item.assessment.level }">
            {{ item.assessment.level || 'No Data' }}
          </dd>

          <dt>Frequency</dt>
          <dd :class="{ empty: !item.assessment.frequency }">
            {{ item.assessment.frequency || 'No Data' }}
          </dd>

          <dt>Measurement</dt>
          <dd :class="{ empty: !item.assessment.measurement }">
            {{ item.assessment.measurement || 'No Data' }}
          </dd>

          <dt>Practice type</dt>
          <dd :class="{ empty: !item.assessment.practiceType }">
            {{ item.assessment.practiceType || 'No Data' }}
          </dd>

          <dt>Goal</dt>
          <dd :class="{ empty: !item.assessment.goal }">
            {{ item.assessment.goal || 'No Data' }}
          </dd>

          <template v-if="item.assessment.entryDataMethod">
            <dt>Entry data method</dt>
            <dd>{{ item.assessment.entryDataMethod }}</dd>
          </template>

          <template v-if="item.assessment.attempts">
            <dt>Attempts</dt>
            <dd>{{ item.assessment.attempts }}</dd>
          </template>

          <template v-if="item.assessment.distance">
            <dt>Distance</dt>
            <dd>{{ item.assessment.distance }}</dd>
          </template>

          <template v-if="item.assessment.distanceUnit">
            <dt>Distance Unit</dt>
            <dd>{{ item.assessment.distanceUnit }}</dd>
          </template>

          <dt>Exercise priority</dt>
          <dd :class="{ empty: !item.assessment.exercisePriority }">
            {{ item.assessment.exercisePriority || 'No Data' }}
          </dd>

          <dt>Scoring descriptions</dt>
          <dd
            :class="{ empty: !item.assessment.scoringDescriptions || item.assessment.scoringDescriptions.length === 0 }"
          >
            <ol v-if="item.assessment.scoringDescriptions && item.assessment.scoringDescriptions.length">
              <li v-for="(desc, i) in item.assessment.scoringDescriptions" :key="i">
                {{ desc }}
              </li>
            </ol>
            <span v-else>No Data</span>
          </dd>

          <dt>Tips</dt>
          <dd :class="{ empty: !item.assessment.tips || item.assessment.tips.length === 0 }">
            <ul v-if="item.assessment.tips && item.assessment.tips.length">
              <li v-for="(tip, i) in item.assessment.tips" :key="i">
                <div v-if="tip.imageUrl">
                  <img :src="tip.imageUrl" alt="tip image" />
                </div>
                <span v-else class="empty">No Data<br /></span>
                <span>{{ tip.title }}</span>
                <span v-if="tip.subtitle">: {{ tip.subtitle }}</span>
                <span>({{ tip.type }})</span>
              </li>
            </ul>
            <span v-else>No Data</span>
          </dd>
        </dl>
        <br />
        <v-divider></v-divider>
        <br />
        <h2>Exercise</h2>
        <br />
        <dl>
          <dt>Unique key</dt>
          <dd :class="{ empty: !item.assessment.exercise.uniqueKey }">
            {{ item.assessment.exercise.uniqueKey || 'No Data' }}
          </dd>

          <dt>Category</dt>
          <dd :class="{ empty: !item.assessment.exercise.category }">
            {{ item.assessment.exercise.category || 'No Data' }}
          </dd>

          <dt>Name</dt>
          <dd :class="{ empty: !item.assessment.exercise.name }">
            {{ item.assessment.exercise.name || 'No Data' }}
          </dd>

          <dt>Tags</dt>
          <dd :class="{ empty: !item.assessment.exercise.tags || item.assessment.exercise.tags.length === 0 }">
            {{
              item.assessment.exercise.tags && item.assessment.exercise.tags.length
                ? item.assessment.exercise.tags.join(', ')
                : 'No Data'
            }}
          </dd>

          <dt>Search tags</dt>
          <dd
            :class="{ empty: !item.assessment.exercise.searchTags || item.assessment.exercise.searchTags.length === 0 }"
          >
            {{
              item.assessment.exercise.searchTags && item.assessment.exercise.searchTags.length
                ? item.assessment.exercise.searchTags.join(', ')
                : 'No Data'
            }}
          </dd>

          <dt>Time</dt>
          <dd :class="{ empty: !item.assessment.exercise.time }">
            {{ item.assessment.exercise.time || 'No Data' }}
          </dd>

          <dt>Thumbnail</dt>
          <dd :class="{ empty: !item.assessment.exercise.thumbnail }">
            <img v-if="item.assessment.exercise.thumbnail" :src="item.assessment.exercise.thumbnail" />
            <span v-else>No Data</span>
          </dd>

          <dt>Thumbnail Female</dt>
          <dd :class="{ empty: !item.assessment.exercise.thumbnailFemale }">
            <img v-if="item.assessment.exercise.thumbnailFemale" :src="item.assessment.exercise.thumbnailFemale" />
            <span v-else>No Data</span>
          </dd>

          <dt>Video</dt>
          <dd :class="{ empty: !item.assessment.exercise.video.url }">
            <a v-if="item.assessment.exercise.video.url" :href="item.assessment.exercise.video.url" target="_blank"
              >Video</a
            >
            <span v-else>No Data</span>
          </dd>

          <dt>Video Female</dt>
          <dd :class="{ empty: !item.assessment.exercise.videoFemale.url }">
            <a
              v-if="item.assessment.exercise.videoFemale.url"
              :href="item.assessment.exercise.videoFemale.url"
              target="_blank"
              >Video Female</a
            >
            <span v-else>No Data</span>
          </dd>

          <dt>Instruction</dt>
          <dd :class="{ empty: !item.assessment.exercise.instruction.description }">
            {{ item.assessment.exercise.instruction.description || 'No Data' }}
          </dd>

          <dt>Instruction Image</dt>
          <dd :class="{ empty: !item.assessment.exercise.instruction.imageUrl }">
            <img
              v-if="item.assessment.exercise.instruction.imageUrl"
              :src="item.assessment.exercise.instruction.imageUrl"
            />
            <span v-else>No Data</span>
          </dd>

          <dt>Instruction Image Female</dt>
          <dd :class="{ empty: !item.assessment.exercise.instruction.imageUrlFemale }">
            <img
              v-if="item.assessment.exercise.instruction.imageUrlFemale"
              :src="item.assessment.exercise.instruction.imageUrlFemale"
            />
            <span v-else>No Data</span>
          </dd>

          <dt>Countdown Time</dt>
          <dd :class="{ empty: !item.assessment.exercise.countdownTime }">
            {{ item.assessment.exercise.countdownTime || 'No Data' }}
          </dd>
        </dl>
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>

<script>
import { computed, ref } from 'vue'

export default {
  props: {
    item: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    const tabsNavigation = ref(0)

    const allAssessments = computed(() => {
      const assessments = []
      const sessions = props.item.sessions || []
      sessions.forEach((session, sIndex) => {
        ;(session.assessments || []).forEach((assessment, aIndex) => {
          assessments.push({
            assessment,
            session,
            sIndex,
            aIndex,
          })
        })
      })
      return assessments
    })

    return {
      tabsNavigation,
      allAssessments,
    }
  },
}
</script>

<style scoped>
.empty {
  color: red;
}
</style>
