<template>
  <v-dialog :value="value" max-width="1000" scrollable persistent @input="$emit('input', $event)">
    <v-card>
      <v-card-title>{{ formTitle }}</v-card-title>
      <v-card-text>
        <ValidationObserver ref="formObserver">
          <v-form :disabled="isLoading">
            <v-container>
              <v-row>
                <v-col cols="12" sm="4">
                  <ValidationProvider name="Id">
                    <template v-slot="{ errors }">
                      <v-text-field v-model="item.id" label="Id" :error-messages="errors" disabled />
                    </template>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="4">
                  <ValidationProvider name="Name" rules="required|max:128">
                    <template v-slot="{ errors }">
                      <v-text-field
                        v-model="item.name"
                        label="Name*"
                        :error-messages="errors"
                      />
                    </template>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="4">
                  <ValidationProvider name="Category" rules="required">
                    <template v-slot="{ errors }">
                      <v-select
                        v-model="item.category"
                        :items="sessionCategoryOptions"
                        label="Category*"
                        :error-messages="errors"
                        @change="filterSessionTypes"
                      />
                    </template>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-checkbox v-model="item.active" label="Active" />
                </v-col>
              </v-row>

              <v-row v-for="(session, index) in item.sessions" :key="`session-${index}`">
                <v-col cols="12">
                  <v-card>
                    <v-card-title>
                      Session {{ index + 1 }}
                      <v-spacer></v-spacer>
                      <v-btn icon @click="removeSession(index)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <ValidationProvider :name="`Session Type ${index + 1}`" rules="required">
                            <template v-slot="{ errors }">
                              <v-select
                                v-model="session.type"
                                :items="filteredSessionTypeOptions"
                                label="Session Type*"
                                :error-messages="errors"
                                :disabled="!item.category"
                                @change="filterAssessments(index)"
                              />
                            </template>
                          </ValidationProvider>
                        </v-col>

                        <v-col cols="12" sm="6">
                          <ValidationProvider :name="`Assessments ${index + 1}`" rules="required">
                            <template v-slot="{ errors }">
                              <v-autocomplete
                                v-model="session.assessments"
                                label="Add Assessments*"
                                :items="filteredAssessments[index]"
                                item-text="titleOfSession"
                                item-value="id"
                                return-object
                                multiple
                                clearable
                                chips
                                :error-messages="errors"
                                :disabled="!session.type"
                              >
                                <template v-slot:selection="data">
                                  <v-chip
                                    close
                                    small
                                    v-bind="data.attrs"
                                    :input-value="data.selected"
                                    @click="data.select"
                                    @click:close="removeSelectedAssessment(index, data.item)"
                                  >
                                    {{ data.item.titleOfSession }} - {{ data.item.sessionType }} - {{ data.item.category }} - {{ data.item.level }} - {{ data.item.measurement }}
                                  </v-chip>
                                </template>
                                <template v-slot:item="data">
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      {{ data.item.titleOfSession }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                      <v-chip small>{{ data.item.sessionType }}</v-chip>
                                      <v-chip small>{{ data.item.category }}</v-chip>
                                      <v-chip small>{{ data.item.level }}</v-chip>
                                      <v-chip small>{{ data.item.measurement }}</v-chip>
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </template>
                              </v-autocomplete>
                            </template>
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-btn text color="secondary" @click="addSession">Add Session</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </ValidationObserver>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="onCancel">Cancel</v-btn>
        <v-btn :loading="isLoading" color="primary" @click="onSave">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import useGolfAssessments from '@/composables/api/assessments/golf'
  import useFitnessAssessments from '@/composables/api/assessments/fitness'
  import { SessionCategory, SessionType } from '@/enums/enums'

  export default {
    components: {
      ValidationObserver,
      ValidationProvider,
    },
    props: {
      value: Boolean,
      item: {
        type: Object,
        default: () => ({
          id: '',
          name: '',
          category: '',
          sessions: [],
        }),
      },
      formTitle: String,
      isLoading: Boolean,
      icons: Object,
    },
    data() {
      return {
        sessionTypeOptions: Object.values(SessionType),
        sessionCategoryOptions: Object.values(SessionCategory).filter(
          (category) => category !== "play"
        ),
        filteredSessionTypeOptions: [],
        allAssessments: [],
        filteredAssessments: [],
        overallAssessmentError: '',
      }
    },
    watch: {
      item: {
        immediate: true,
        handler(newVal) {
          if (!newVal.sessions) {
            newVal.sessions = [];
          }
          
          if (newVal.sessions.length === 0 && newVal.id) {
            if (newVal._originalSessions && newVal._originalSessions.length > 0) {
              this.$set(newVal, 'sessions', JSON.parse(JSON.stringify(newVal._originalSessions)));
            } else if (newVal.assessmentsList && newVal.assessmentsList.length > 0) {
              const sessionMap = {};
              
              newVal.assessmentsList.forEach(assessment => {
                const sessionType = assessment.sessionType || 'default';
                
                if (!sessionMap[sessionType]) {
                  sessionMap[sessionType] = {
                    type: sessionType,
                    category: newVal.category,
                    assessments: []
                  };
                }
                
                sessionMap[sessionType].assessments.push(assessment);
              });
              
              const sessionsArray = Object.values(sessionMap);
              this.$set(newVal, 'sessions', sessionsArray);
            }
          }

          this.filteredAssessments = Array(newVal.sessions.length).fill().map(() => []);
          
          if (newVal.category) {
            this.filterSessionTypes();
            
            newVal.sessions.forEach((session, index) => {
              if (session.type) {
                this.filterAssessments(index);
              }
            });
          }
        }
      }
    },
    async created() {
      const { fetchGolfAssessments, golfAssessments } = useGolfAssessments()
      const { fetchFitnessAssessments, fitnessAssessments } = useFitnessAssessments()

      await fetchGolfAssessments()
      await fetchFitnessAssessments()

      this.allAssessments = [...golfAssessments.value, ...fitnessAssessments.value]
        .map(assessment => {
          if (!assessment.exercise) {
            assessment.exercise = {
              uniqueKey: '',
              category: '',
              name: '',
              video: { url: '' },
              videoFemale: { url: '' },
              instruction: { description: '', imageUrl: '', imageUrlFemale: '' }
            };
          }
          return assessment;
        });

      if (this.item.active === undefined) {
        this.item.active = true
      }
      
      if (this.item.category) {
        this.filterSessionTypes();
        
        if (this.item.sessions) {
          this.item.sessions.forEach((session, index) => {
            if (session.type) {
              this.filterAssessments(index);
            }
          });
        }
      }
    },
    methods: {
      filterSessionTypes() {
        if (this.item.category) {
          const categoryNormalized = this.item.category.toLowerCase()
          this.filteredSessionTypeOptions = this.sessionTypeOptions.filter((type) =>
            this.allAssessments.some(
              (assessment) =>
                assessment.exercise &&
                assessment.exercise.category.toLowerCase() === categoryNormalized &&
                assessment.sessionType.toLowerCase() === type.toLowerCase()
            )
          )
        } else {
          this.filteredSessionTypeOptions = []
        }
      },
      filterAssessments(index) {
        const session = this.item.sessions[index];
        const { type } = session;
        
        if (type && this.item.category) {
          const categoryNormalized = this.item.category.toLowerCase();
          const typeNormalized = type.toString().toLowerCase();
          
          const matchingAssessments = this.allAssessments.filter(
            (a) =>
              a.exercise &&
              a.exercise.category &&
              a.exercise.category.toLowerCase() === categoryNormalized &&
              a.sessionType &&
              a.sessionType.toLowerCase() === typeNormalized
          );
          
          this.$set(this.filteredAssessments, index, matchingAssessments);
          
          if (session.assessments && session.assessments.length > 0) {
            session.assessments = session.assessments.map(assessment => {
              const fullAssessment = this.allAssessments.find(a => a.id === assessment.id);
              
              if (fullAssessment) {
                return fullAssessment;
              }
              
              return assessment;
            });
          }
        } else {
          this.$set(this.filteredAssessments, index, []);
        }
      },
      removeSelectedAssessment(index, item) {
        const session = this.item.sessions[index]
        session.assessments = session.assessments.filter(a => a.id !== item.id)
      },
      addSession() {
        this.item.sessions.push({
          type: '',
          assessments: [],
        })
        this.filteredAssessments.push([])
      },
      removeSession(index) {
        this.item.sessions.splice(index, 1)
        this.filteredAssessments.splice(index, 1)
      },
      async onSave() {
        this.overallAssessmentError = '';
        const isValid = await this.$refs.formObserver.validate();
        
        if (isValid) {
          if (this.item.sessions.every(session => session.assessments && session.assessments.length > 0)) {
            const saveData = {
              name: this.item.name,
              category: this.item.category,
              sessions: this.item.sessions.map(session => ({
                type: session.type,
                category: this.item.category,
                assessments: session.assessments
                  .filter(a => a && a.id && a.id !== '')
                  .map(a => ({
                    id: a.id,
                    titleOfSession: a.titleOfSession,
                    uniqueKey: a.uniqueKey,
                    sessionType: a.sessionType || session.type,
                    category: a.category || this.item.category,
                    level: a.level,
                    frequency: a.frequency,
                    measurement: a.measurement,
                    practiceType: a.practiceType,
                    goal: a.goal,
                    exercisePriority: a.exercisePriority,
                    tips: a.tips || [],
                    scoringDescriptions: a.scoringDescriptions || [],
                    exercise: a.exercise,
                    exerciseUniqueKey: a.exerciseUniqueKey || (a.exercise && a.exercise.uniqueKey),
                    attempts: a.attempts || undefined,
                    entryDataMethod: a.entryDataMethod || undefined,
                    distance: a.distance || undefined,
                    distanceUnit: a.distanceUnit || undefined,
                  })),
              })),
              active: this.item.active
            }
            if (this.item.id !== '') {
              saveData.id = this.item.id;
            }
            this.$emit('save', saveData);
          } else {
            this.overallAssessmentError = 'At least one assessment must be selected in each session.';
          }
        }
      },
      onCancel() {
        this.$emit('cancel')
      },
    },
  }
</script>
