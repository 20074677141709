import { ref } from 'vue'
import axios from 'axios'
import useSnackbar, { SnackbarType } from '@/composables/snackbar'

export default function useSessionAssessments() {
  const RESOURCE = '/assessment/group'

  const { showSnackbar } = useSnackbar()

  const dtHeaders = [
    {
      text: 'NAME', value: 'name',
    },
    {
      text: 'CATEGORY', value: 'category', width: 120,
    },
    {
      text: 'ACTIVE', value: 'active', width: 100,
    },
    {
      text: 'ACTIONS', value: 'actions', sortable: false, width: 100, align: 'center',
    },
  ]

  const dtSearchQuery = ref('')
  const dtExpanded = ref([])

  const blankSessionAssessment = {
    id: '',
    name: '',
    category: '',
    sessions: [
      {
        type: '',
        category: '',
        assessments: [
          {
            id: '',
            titleOfSession: '',
            uniqueKey: '',
            sessionType: '',
            category: '',
            level: '',
            frequency: '',
            measurement: '',
            practiceType: '',
            goal: '',
            exercisePriority: '',
            tips: [],
            scoringDescriptions: [],
            exercise: null,
            exerciseUniqueKey: '',
          },
        ]
      }
    ]
  }

  const sessionAssessments = ref([])
  const sessionAssessment = ref(structuredClone(blankSessionAssessment))

  const isLoading = ref(false)

  const createSessionAssessment = async sessionAssessmentItem => {
    isLoading.value = true

    await axios.post(RESOURCE, sessionAssessmentItem)
      .then(() => {
        showSnackbar('You have successfully created a new session assessment', SnackbarType.SUCCESS)
      })
      .catch(error => {
        const message = error.response?.data?.message ?? error.message
        const statusCode = error.response?.status ?? null

        showSnackbar(
          `Failed to create a new session assessment:<br> ${statusCode ? `[${statusCode}]` : ''} ${message}`,
          SnackbarType.ERROR,
        )

        throw error
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  const deleteSessionAssessment = async sessionAssessmentItem => {
    isLoading.value = true

    await axios.delete(`${RESOURCE}/delete/${sessionAssessmentItem.id}`)
      .then(() => {
        showSnackbar('You have successfully deleted the session assessment', SnackbarType.SUCCESS)
      })
      .catch(error => {
        const message = error.response?.data?.message ?? error.message
        const statusCode = error.response?.status ?? null

        showSnackbar(
          `Failed to delete the session assessment:<br> ${statusCode ? `[${statusCode}]` : ''} ${message}`,
          SnackbarType.ERROR,
        )

        throw error
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  const fetchSessionAssessments = async () => {
    isLoading.value = true

    await axios.get(RESOURCE)
      .then(response => {
        sessionAssessments.value = response.data
      })
      .catch(error => {
        const message = error.response?.data?.message ?? error.message
        const statusCode = error.response?.status ?? null

        showSnackbar(
          `Failed to fetch session assessments:<br> ${statusCode ? `[${statusCode}]` : ''} ${message}`,
          SnackbarType.ERROR,
        )

        throw error
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  const updateSessionAssessment = async sessionAssessmentItem => {
    isLoading.value = true

    await axios.put(RESOURCE, sessionAssessmentItem)
      .then(() => {
        showSnackbar('You have successfully updated the session assessment', SnackbarType.SUCCESS)
      })
      .catch(error => {
        const message = error.response?.data?.message ?? error.message
        const statusCode = error.response?.status ?? null

        showSnackbar(
          `Failed to update the session assessment:<br> ${statusCode ? `[${statusCode}]` : ''} ${message}`,
          SnackbarType.ERROR,
        )

        throw error
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  const updateManySessionAssessments = async (type, paramName, paramValue, ids) => {
    isLoading.value = true

    const payload = {
      type,
      params: [
        {
          name: paramName,
          value: paramValue,
          ids,
        },
      ],
    }

    try {
      await axios.put('/library/updateMany', payload)

      showSnackbar(`Successfully updated ${ids.length} practice session(s)`, SnackbarType.SUCCESS)

      return true
    } catch (error) {
      const message = error.response?.data?.message ?? error.message
      const statusCode = error.response?.status ?? null

      showSnackbar(
        `Failed to update practice sessions:<br> ${statusCode ? `[${statusCode}]` : ''} ${message}`,
        SnackbarType.ERROR,
      )

      throw error
    } finally {
      isLoading.value = false
    }
  }

  return {
    dtHeaders,
    dtSearchQuery,
    dtExpanded,

    blankSessionAssessment,
    sessionAssessments,
    sessionAssessment,

    isLoading,

    createSessionAssessment,
    deleteSessionAssessment,
    fetchSessionAssessments,
    updateSessionAssessment,
    updateManySessionAssessments,
  }
}
