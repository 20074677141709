<template>
  <div>
    <v-card>
      <v-card-title>
        Practice Sessions Library
        <v-spacer />

        <v-btn v-if="selected.length > 0" class="mr-2 mb-2" color="secondary" @click="showBulkStatusDialog">
          UPDATE SELECTED ({{ selected.length }})
        </v-btn>

        <v-btn class="mb-2" color="primary" dark @click="showEditDialog(blankSessionAssessment)"> CREATE </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="4" offset-md="8">
            <v-text-field
              v-model="dtSearchQuery"
              :append-icon="icons.mdiMagnify"
              label="Search"
              single-line
              hide-details
              dense
              outlined
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-data-table
        v-model="selected"
        :headers="dtHeaders"
        :items="sessionAssessments"
        :search="dtSearchQuery"
        :expanded.sync="dtExpanded"
        :loading="isLoading"
        :items-per-page="30"
        :footer-props="{ 'items-per-page-options': [15, 30, 45] }"
        show-select
        single-expand
        show-expand
        item-key="id"
      >
        <template v-slot:item.category="{ item }">
          <v-chip>
            {{ item.category }}
          </v-chip>
        </template>

        <template v-slot:item.active="{ item }">
          <v-chip :color="item.active ? 'success' : 'error'" dark>
            {{ item.active ? 'Active' : 'Inactive' }}
          </v-chip>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon class="mr-2" @click="showEditDialog(item)">
            {{ icons.mdiPencilOutline }}
          </v-icon>
          <v-icon @click="showDeleteConfirmationDialog(item)">
            {{ icons.mdiDeleteOutline }}
          </v-icon>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pa-0">
            <practice-sessions-details :item="item" />
          </td>
        </template>
      </v-data-table>

      <delete-dialog
        v-model="isDeleteDialogVisible"
        :item="sessionAssessment"
        :is-loading="isLoading"
        @cancel="onDeleteCanceled"
        @delete="onDeleteConfirmed"
      />

      <practice-sessions-edit-dialog
        v-if="isEditDialogVisible"
        v-model="isEditDialogVisible"
        :item="sessionAssessment"
        :is-loading="isLoading"
        @cancel="onEditCanceled"
        @save="onEditSave"
      />

      <v-dialog v-model="isBulkStatusDialogVisible" max-width="500">
        <v-card>
          <v-card-title>Update Status for Selected Items</v-card-title>
          <v-card-text>
            <p>You are about to update the status of {{ selected.length }} selected practice session(s).</p>
            <v-select
              v-model="bulkStatusValue"
              :items="[
                { text: 'Mark as Active', value: true },
                { text: 'Mark as Inactive', value: false },
              ]"
              label="Select Status"
              outlined
            ></v-select>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="isBulkStatusDialogVisible = false">Cancel</v-btn>
            <v-btn color="primary" :loading="isBulkUpdating" @click="updateBulkStatus"> Confirm </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import { mdiCheck, mdiClose, mdiDeleteOutline, mdiPencilOutline, mdiMagnify } from '@mdi/js'
import { onMounted, ref } from 'vue'
import DeleteDialog from '@/components/DeleteDialog.vue'
import PracticeSessionsDetails from '@/views/practiceSessions/partials/PracticeSessionsDetails.vue'
import PracticeSessionsEditDialog from '@/views/practiceSessions/partials/PracticeSessionsEditDialog.vue'
import useSessionAssessments from '@/composables/api/assessments/session'
import useUniqueKey from '@/composables/uniqueKey'

export default {
  components: {
    DeleteDialog,
    PracticeSessionsDetails,
    PracticeSessionsEditDialog,
  },

  setup() {
    const {
      dtHeaders,
      dtExpanded,
      dtSearchQuery,
      blankSessionAssessment,
      sessionAssessment,
      sessionAssessments,
      isLoading,
      createSessionAssessment,
      deleteSessionAssessment,
      fetchSessionAssessments,
      updateSessionAssessment,
      updateManySessionAssessments,
    } = useSessionAssessments()

    const { uniqueKey } = useUniqueKey()

    const isDeleteDialogVisible = ref(false)
    const isEditDialogVisible = ref(false)
    const selected = ref([])
    const isBulkStatusDialogVisible = ref(false)
    const bulkStatusValue = ref(true)
    const isBulkUpdating = ref(false)

    onMounted(async () => {
      await fetchSessionAssessments()
    })

    const showDeleteConfirmationDialog = sessionAssessmentItem => {
      sessionAssessment.value = sessionAssessmentItem
      isDeleteDialogVisible.value = true
    }

    const onDeleteCanceled = () => {
      sessionAssessment.value = structuredClone(blankSessionAssessment)
      isDeleteDialogVisible.value = false
    }

    const onDeleteConfirmed = async sessionAssessmentItem => {
      await deleteSessionAssessment(sessionAssessmentItem)
      await fetchSessionAssessments()

      isDeleteDialogVisible.value = false
    }

    const showEditDialog = sessionAssessmentItem => {
      const item = structuredClone(sessionAssessmentItem)

      if (!item.uniqueKey) {
        item.uniqueKey = uniqueKey()
      }

      if ((!item.assessmentsList || item.assessmentsList.length === 0) && item.sessions && item.sessions.length > 0) {
        item.assessmentsList = []
        ;(item.sessions || []).forEach(session => {
          ;(session.assessments || []).forEach(assessment => {
            const mappedAssessment = {
              ...assessment,
              sessionType: session.type,
              sessionCategory: session.category,
              exercise: assessment.exercise || {
                uniqueKey: '',
                category: '',
                name: '',
                tags: [],
                searchTags: [],
                time: '',
                thumbnail: '',
                thumbnailFemale: '',
                video: { url: '' },
                videoFemale: { url: '' },
                instruction: {
                  description: '',
                  imageUrl: '',
                  imageUrlFemale: '',
                },
                countdownTime: '',
              },
            }

            item.assessmentsList.push(mappedAssessment)
          })
        })

        item._originalSessions = item.sessions
      }

      if (!item.assessmentsList) {
        item.assessmentsList = []
      }

      if (item.assessmentsList.length === 0) {
        item.assessmentsList.push({
          id: '',
          uniqueKey: uniqueKey(),
          sessionType: '',
          category: '',
          level: '',
          frequency: '',
          measurement: '',
          practiceType: '',
          goal: '',
          exercisePriority: '',
          tips: [],
          scoringDescriptions: [],
          exercise: {
            uniqueKey: '',
            category: '',
            name: '',
            tags: [],
            searchTags: [],
            time: '',
            thumbnail: '',
            thumbnailFemale: '',
            video: { url: '' },
            videoFemale: { url: '' },
            instruction: {
              description: '',
              imageUrl: '',
              imageUrlFemale: '',
            },
            countdownTime: '',
          },
          exerciseUniqueKey: '',
        })
      }

      sessionAssessment.value = item
      isEditDialogVisible.value = true
    }

    const onEditCanceled = () => {
      sessionAssessment.value = structuredClone(blankSessionAssessment)
      isEditDialogVisible.value = false
    }

    const onEditSave = async sessionAssessmentItem => {
      try {
        const itemToSave = structuredClone(sessionAssessmentItem)

        if (itemToSave.id && itemToSave.assessmentsList && itemToSave.assessmentsList.length > 0) {
          const sessionTypeMap = {}

          itemToSave.assessmentsList.forEach(assessment => {
            const sessionType = assessment.sessionType || 'default'
            if (!sessionTypeMap[sessionType]) {
              sessionTypeMap[sessionType] = {
                type: sessionType,
                category: assessment.sessionCategory || assessment.category || itemToSave.category,
                assessments: [],
              }
            }
            sessionTypeMap[sessionType].assessments.push(assessment)
          })

          itemToSave.sessions = Object.values(sessionTypeMap)
        }

        if (!itemToSave.id) {
          await createSessionAssessment(itemToSave)
          await fetchSessionAssessments()
        } else {
          await updateSessionAssessment(itemToSave)
          await fetchSessionAssessments()
        }

        isEditDialogVisible.value = false
      } catch (error) {
        console.error(error)
      }
    }

    const showBulkStatusDialog = () => {
      isBulkStatusDialogVisible.value = true
    }

    const updateBulkStatus = async () => {
      if (selected.value.length === 0) return

      isBulkUpdating.value = true

      try {
        await updateManySessionAssessments(
          'PRACTICE_SESSION',
          'active',
          bulkStatusValue.value,
          selected.value.map(item => item.id),
        )

        await fetchSessionAssessments()

        selected.value = []
        isBulkStatusDialogVisible.value = false
      } catch (error) {
        console.error(error)
      } finally {
        isBulkUpdating.value = false
      }
    }

    return {
      dtHeaders,
      dtExpanded,
      dtSearchQuery,

      blankSessionAssessment,
      sessionAssessments,
      sessionAssessment,

      isDeleteDialogVisible,
      showDeleteConfirmationDialog,
      onDeleteCanceled,
      onDeleteConfirmed,

      isEditDialogVisible,
      showEditDialog,
      onEditCanceled,
      onEditSave,

      isLoading,

      selected,
      isBulkStatusDialogVisible,
      bulkStatusValue,
      isBulkUpdating,
      showBulkStatusDialog,
      updateBulkStatus,

      icons: {
        mdiCheck,
        mdiClose,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiMagnify,
      },
    }
  },
}
</script>
